<template>
  <v-card-text>
    <div v-if="isLoading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-else>
      <v-snackbar
        v-model="snackbar"
        :multi-line="true"
        color="warning"
        timeout="-1"
        outlined
        absolute
        top
        right
        transition="slide-x-reverse-transition"
      >
        {{ error }}

        <template v-slot:action="{ attrs }">
          <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            <span class="d-none" aria-hidden="true">Close</span>
          </v-btn>
        </template>
      </v-snackbar>
      <v-card>
        <v-card-text>
          <site-locations-form
            :siteLocation="siteLocation"
            ref="siteLocationForm"
          ></site-locations-form>
        </v-card-text>
      </v-card>
    </div>
  </v-card-text>
</template>

<script>
import SiteLocationsForm from "@admin/components/site_locations/Form.vue";
import { mapFields } from "vuex-map-fields";
import { mapActions, mapState } from "vuex";

export default {
  name: "SiteLocationsFormView",
  components: { SiteLocationsForm },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      snackbar: false,
    };
  },
  computed: {
    ...mapFields("siteLocations", ["siteLocation"]),
    ...mapState("countries", ["countries"]),
    ...mapState("siteLocations", ["error"]),
    isNew() {
      return this.id === "";
    },
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setTitleObject",
      "setBreadcrumbs",
    ]),
    ...mapActions("siteLocations", [
      "addSiteLocation",
      "clearSiteLocation",
      "clearSiteLocations",
      "editSiteLocation",
      "getSiteLocation",
    ]),
    async submit() {
      const formValid = await this.$refs.siteLocationForm.validateForm();
      if (this.isSubmitting || !formValid) return;

      let success = false;
      this.isSubmitting = true;

      if (this.isNew) {
        success = await this.addSiteLocation();
      } else {
        success = await this.editSiteLocation(this.id);
      }

      if (success !== false) {
        this.clearSiteLocations(); // force re-pull from db
        const redirect = { name: "SiteLocationsView", params: { id: success } };
        await this.$router.push(redirect);
      } else {
        this.snackbar = !!this.error;
      }

      this.isSubmitting = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    this.setBreadcrumbs();
    let actionButtons = [];
    if (this.isNew) {
      this.clearSiteLocation();
      actionButtons.push({
        component: "CancelButton",
        attributes: {
          to: "/site-locations",
        },
        index: 0,
      });
      this.setTitleObject("Clinical Trial Sites");
    } else {
      await this.getSiteLocation({ id: this.id }).catch(() => {
        this.$router.push({ name: "NotFound" });
      });

      this.setTitleObject(this.siteLocation.name);
      actionButtons.push({
        component: "CancelButton",
        attributes: {
          to: "/site-locations/view/" + this.id,
        },
        index: 0,
      });
    }

    actionButtons.push({
      component: "SaveButton",
      attributes: {
        onSubmit: () => this.submit(),
      },
      index: 1,
    });
    this.setActionButtons(actionButtons);
    this.isLoading = false;
  },
  destroyed() {
    this.clearActionButtons();
    this.clearTitleObject();
    this.clearSiteLocation();
  },
};
</script>

<style scoped></style>
