<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <v-form ref="form" @submit.prevent="handleSubmit(submit)">
      <v-container fluid>
        <!-- Row 1 -->
        <v-row>
          <v-col cols="12" sm="2" v-if="!isNew">
            <dl>
              <dt>Spectrum Site ID:</dt>
              <dd>{{ siteLocation.siteId }}</dd>
            </dl>
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider
              v-slot="{ errors }"
              name="name"
              ref="name"
              rules="required|min:3|max:255"
            >
              <v-text-field
                outlined
                v-model="siteLocation.name"
                :error-messages="errors"
                counter
                maxlength="255"
                hint="The name of the site location"
                label="Site Name"
                required
                aria-required="true"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="3">
            <validation-provider
              v-slot="{ errors }"
              name="isActive"
              rules="required"
            >
              <v-radio-group
                v-model="siteLocation.isActive"
                row
                aria-required="true"
                :error-messages="errors"
              >
                <template v-slot:label>
                  <div>Status</div>
                </template>
                <fieldset>
                  <legend class="d-none" aria-hidden="true">Status</legend>
                  <v-radio :label="`Active`" :value="true"></v-radio>
                  <v-radio :label="`Inactive`" :value="false"></v-radio>
                </fieldset>
              </v-radio-group>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="1">
            <v-checkbox
                v-model="virtualSite"
                class="mx-auto"
                label="Virtual Site"
                :disabled="!isNew"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <!-- Row 2 -->
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="description"
              rules="max:255"
            >
              <v-text-field
                outlined
                v-model="siteLocation.description"
                :error-messages="errors"
                counter
                maxlength="255"
                hint="The description of the site location"
                label="Description"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <!-- Row 3 -->
        <v-row>
          <v-col cols="12" sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="piName"
              rules="required|min:3|max:255"
            >
              <v-text-field
                outlined
                v-model="siteLocation.piName"
                :error-messages="errors"
                counter
                maxlength="255"
                label="PI Name"
                required
                aria-required="true"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="4">
            <!-- Type of Site -->
            <validation-provider
              v-slot="{ errors }"
              name="Type of Site"
              :rules="{ oneOf: siteLocationTypes.map((a) => a.value) }"
            >
              <v-select
                outlined
                :items="siteLocationTypes"
                label="Type of Site"
                :error-messages="errors"
                v-model="siteLocation.siteLocationType"
                aria-required="true"
                required
              ></v-select>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="4">
            <!-- IRB -->
            <validation-provider
              v-slot="{ errors }"
              name="IRB Type"
              :rules="{ oneOf: siteLocationIrbTypes.map((a) => a.value) }"
            >
              <v-select
                outlined
                :items="siteLocationIrbTypes"
                label="IRB Type"
                :error-messages="errors"
                v-model="siteLocation.siteLocationIrbType"
                aria-required="true"
              ></v-select>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="address"
              :rules="virtualSite ? '' : 'required|min:6|max:255'"
            >
              <v-text-field
                outlined
                v-model="siteLocation.streetAddress"
                :error-messages="errors"
                counter
                maxlength="255"
                hint="Site location address"
                label="Address"
                required
                aria-required="true"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="building"
              :rules="virtualSite ? '' : 'min:2|max:255'"
            >
              <v-text-field
                outlined
                v-model="siteLocation.unitNumber"
                :error-messages="errors"
                counter
                maxlength="255"
                label="Building / Unit"
                :disabled="virtualSite"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="city"
              :rules="virtualSite ? '' : 'required|min:1|max:255'"
            >
              <v-text-field
                outlined
                v-model="siteLocation.city"
                :error-messages="errors"
                counter
                maxlength="255"
                label="City"
                required
                aria-required="true"
                :disabled="virtualSite"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="state"
              :rules="virtualSite ? '' : 'required'"
            >
              <v-text-field
                outlined
                v-model="siteLocation.state"
                :error-messages="errors"
                counter
                maxlength="255"
                label="State"
                required
                aria-required="true"
                :disabled="virtualSite"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="zip"
              :rules="virtualSite ? '' : 'required|min:5|max:255'"
            >
              <v-text-field
                outlined
                v-model="siteLocation.zipCode"
                :error-messages="errors"
                counter
                maxlength="255"
                label="Zipcode"
                required
                aria-required="true"
                :disabled="virtualSite"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="4">
            <validation-provider
              v-slot="{ errors }"
              name="Country"
              :rules="{ required: true, oneOf: countries.map((a) => a.value) }"
            >
              <v-select
                outlined
                :items="countries"
                label="Country"
                :error-messages="errors"
                v-model="siteLocation.country"
                aria-required="true"
                required
              ></v-select>
            </validation-provider>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </validation-observer>
</template>

<script>
import { required, min, max, oneOf } from "vee-validate/dist/rules";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { mapActions, mapState } from "vuex";

extend("min", {
  ...min,
  message: "Minimum {length} characters.",
});

extend("max", {
  ...max,
  message: "Maximum {length} characters.",
});

extend("required", {
  ...required,
  message: "Please provide a value.",
});

extend("oneOf", {
  ...oneOf,
  message: "Please provide a value from the list",
});

export default {
  name: "SiteLocationsForm",
  components: { ValidationObserver, ValidationProvider },
  computed: {
    ...mapState("countries", ["countries"]),
    ...mapState("siteLocations", ["siteLocationTypes", "siteLocationIrbTypes"]),
    isNew() {
      return this.id === undefined;
    },
    virtualSite: {
      get() {
        return this.siteLocation.isVirtual;
      },
      set(value) {
        let siteLocation = this.siteLocation;
        siteLocation.isVirtual = value;

        this.$emit("input", siteLocation);
      },
    },
  },
  props: {
    siteLocation: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      engagementId: this.$route.params.engagementId,
      formValid: false,
    };
  },
  methods: {
    ...mapActions("countries", ["getCountries"]),
    validateForm() {
      return this.$refs.observer.validate();
    },
  },
  mounted() {
    this.getCountries();
  },
};
</script>

<style scoped>
fieldset {
  border: 0;
  display: inline-flex;
}
</style>
